<template>
    <div class="container">
        <img class="title-image" src="@/assets/images/center/invitation1.png" alt="">
        <img class="main-image" src="@/assets/images/center/invitation2.png" alt="">
        <button class="max-button" type="button" v-clipboard:copy="share_address" v-clipboard:success="onCopy"
            v-clipboard:error="onError">
            <img src="@/assets/images/copy.png" alt="">
            复制链接
        </button>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            share_address: "123"
        };
    },
    mounted() { },
    methods: {
        onCopy(e) {
            this.$toast({
                message: "复制成功",
                icon: "certificate",
            });
        },
        onError(e) {
            this.$toast({
                message: "复制失败",
                icon: "fail",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    .title-image {
        width: 444px;
        display: block;
        margin: 100px auto 36px;
    }

    .main-image {
        width: 480px;
        display: block;
        margin: 0 auto 38px;
    }
}
</style>